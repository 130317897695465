import { makeStyles } from 'lib/makeStyles';
import dynamic from 'next/dynamic';
import React from 'react';

import { SliderRangeInputProps } from './SliderRangeInput';

const RangeSliderComponent = dynamic(() => import('./SliderRangeInput'));
const DefaultSliderComponent = dynamic(() => import('./SliderDefaultInput'));

export type SliderInputProps = {
  hasRange?: boolean;
} & SliderRangeInputProps;

export const useSliderInputStyles = makeStyles<ParentClassNameProp>()({
  container: ({ className }) => ['w-full h-24', 'relative', className],
  rangeInput: [
    'appearance-none',
    'w-full',
    'outline-none',
    'absolute top-0 bottom-0',
    'm-auto',
    'bg-transparent',
    'pointer-events-none'
  ],
  bar: [
    'rounded-lg',
    'w-full h-2',
    'border-secondaryBorder border',
    'absolute top-0 bottom-0',
    'm-auto',
    'bg-neutralBackgroundLoud'
  ],
  label: ['absolute top-0 left-0', 'my-2'],
  values: ['absolute top-0 right-0', 'mt-2']
});

export const SliderInput = React.memo((props: SliderInputProps) => {
  const { hasRange } = props;

  if (hasRange) return <RangeSliderComponent {...props} />;

  return <DefaultSliderComponent {...props} />;
});

SliderInput.displayName = 'SliderInput';
